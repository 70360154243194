import { observable, action } from "mobx"
import {System,Person,Role, Center,Purpuse,Env} from '../interfaces'
import {API,Auth} from 'aws-amplify';
import jwtDecode from 'jwt-decode'; 
import SupplierStore from './supplierStore';
import { validateEnglishName, validateCellPhone,validateOfficePhone ,validateHebrewName} from "../helpers/validations";
export default class Store {
    // @observable systems:System[] = [{center:"tlv",name:"namer1" , responsible: "tomerL" , 
    //                         dbAdmin : "sa" ,enviroment: "Prod" ,purpuse: "NAMER", oracleSid :"ORA" ,
    //                          ipAddress : "127.0.0.1" , osAdmin: "tomer",osPassword:"Aa123123",dbPassword:"Aa123123",showPassword:false},
    //                          {center:"tlv",name:"namer2" , responsible: "tomerL" , 
    //                          dbAdmin : "sa" ,enviroment: "Prod",purpuse: "NAMER", oracleSid :"ORA" ,
    //                           ipAddress : "127.0.0.1" , osAdmin: "tomer",osPassword:"Aa123123",dbPassword:"Aa123123",showPassword:false} , 
    //                           {center:"tlv",name:"namer3" , responsible: "tomerL" , 
    //                           dbAdmin : "sa" ,enviroment: "Prod" ,purpuse: "NAMER", oracleSid :"ORA" ,
    //                            ipAddress : "127.0.0.1" , osAdmin: "tomer",osPassword:"Aa123123",dbPassword:"Aa123123",showPassword:false}]
    @observable systems:System[] = [];
    // @observable persons:Person[] = [{center:'shamir',email:'tomer@gmail.com',firstname:'tomer' , lastname:'laniado' , 
    //                                       firstname_heb:'תומר' , lastname_heb: 'לניאדו' , phone_number:'0542200201',role:'everything',
    //                                        role_heb:'הכל',office_numbe:''}];
    @observable persons:Person[] = [];
    @observable centers:Center[] = [];
    @observable isEditable:boolean = false;
    @observable systemSnackbarOpen = false;
    @observable systemSnackbarMessage = '';
    @observable personSnackbarOpen = false;
    @observable personSnackbarMessage = '';
    @observable user_group = '';
    @observable roles:Role[] = [{name:'CTO',description:"מנהל טכנולוגיות(CTO)"},
                                {name:"CIO" ,description:"מנהל מערכות מידע(CIO)"} ,
                                {name:"DBA",description:"מנהל בסיסי נתונים(DBA)"},
                                {name:"CISO",description:"מנהל אבטחת מידע(CISO)"},
                                {name:"APP_OWNER",description:"אחראי אפליקציה(App Owner)"},
                                {name:"SYS_ADMIN" , description:"מנהל סיסטם(sysadmin)"}];
    @observable purpuses:Purpuse[] = [{name:'clinic' , description:'קליני(Clinic)'},
                                      {name:'logistic' , description:'לוגיסטי(Logistic)'},
                                      {name:'hr' , description:'משאבי אנוש(HR)'},
                                       {name:'administrative',description:'אדמיניסטרטיבי(Administrative)'},
                                       {name: 'labs' , description:'מעבדות(Labs)'},
                                       {name:'bi' , description:'BI'},
                                       {name:'maintenance' , description:'תחזוקה(Maintenance)'},
                                       {name:'finance' , description: 'פיננסי(Finance)'},
                                       {name:'pacs' , description: 'דימות(PACS)'},
                                       {name:'oncology' , description:'אונקולוגיה(Oncology)'},
                                       {name: 'monitors' , description: 'מוניטורים(Monitors)'},
                                       {name:'delivery' , description:'חדרי לידה(Delivery Rooms)'},
                                       {name:'access' , description:'בקרת כניסה(Access Control)'},
                                       {name:'ivf' , description:'פריון(IVF)'}, 
                                        {name:'other' ,description:'Other'}]
    @observable centerTypes:any[] = [{type:'Psychiatric',description:"בית חולים פסיכיאטרי(Psychiatric Hospital)"},
                                      {type:"gariatric", description:"בית חולים גריאטרי(Gariatric Hospital)"},
                                     {type:"General" , description: "בית חולים כללי(General Hospital)"}];
    @observable environments:Env[] = [{name:'prod' , description:'Prod'},
                                       {name:'qa' , description:'QA'},
                                       {name:'dev', description:'Dev'},
                                        {name:'other' , description:'Other'}]
    @observable personSelection:any = {}; 
    @observable roleSelection:any = {}
    @observable centerSelection:any = {}
    @observable centerTypeSelection:any = {}
    @observable purpuseSelection:any = {};
    @observable environmentSelection:any = {};
    @observable centerDesc = '';
    @observable changeModalOpen = false;
    @observable modalMessage='';
    supplierStore:SupplierStore;
    constructor() {
        this.personList = this.personList.bind(this);
        this.closeSystemSnackbar = this.closeSystemSnackbar.bind(this);
        this.closePersonSnackbar = this.closePersonSnackbar.bind(this);
        this.openChangeModal = this.openChangeModal.bind(this);
        this.closeChangeModal = this.closeChangeModal.bind(this);
        this.roleList();
        this.centerTypeList();
        this.purpuseList();
        this.environmentList();
        this.supplierStore = new SupplierStore(this);
    }

    @action setModal(key:string)
    {
        const keyJ = JSON.parse(key);
        let modalEmail;
        let modalDate;
        if(keyJ.type==='system')
        {
            const system = this.systems.find(x=>x.center === keyJ.center && x.name === keyJ.name);
            if(system)
            {
            modalEmail = system.lastChangeEmail;
            modalDate = system.lastChangeDate;
            }     
        }
        else if(keyJ.type==='person')
        {
            const person = this.persons.find(x=>x.center === keyJ.center && x.email === keyJ.email);
            if(person)
            {
            modalEmail = person.lastChangeEmail;
            modalDate = person.lastChangeDate;
            }     
        }
        else if(keyJ.type==='center')
        {
            const center = this.centers.find(x=>x.tag === keyJ.tag);
            if(center)
            {
            modalEmail = center.lastChangeEmail;
            modalDate = center.lastChangeDate;
            }     
        }

        if(modalEmail)
        {
            this.modalMessage = `${modalDate} : ${modalEmail}`;
        }
        else
        {
            this.modalMessage = "No Changes Recorded yet";
        }
    }
    @action openChangeModal(){
        this.changeModalOpen = true;
    }
    @action closeChangeModal(){
        this.changeModalOpen = false;
    }

    @action signout(){
        Auth.signOut();
    }
    @action chanegPasswordState = (systemName:string) =>  {
        const systemIndex:number = this.systems.findIndex(x => x.name === systemName);
        let tempSystems = this.systems.slice();
        tempSystems[systemIndex].showPassword = !tempSystems[systemIndex].showPassword
        this.systems = tempSystems;
    }
    @action personList() {
        let s:any = {};
    this.persons.forEach(person => {
      s[person.email] = `${person.firstname} ${person.lastname}`;
    });
     this.personSelection = s;
  }

  @action  roleList() {
    let s:any = {};
this.roles.forEach(role => {
  s[role.name] = role.description;
});
   this.roleSelection =  s;
} 
@action  purpuseList() {
    let s:any = {};
this.purpuses.forEach(purpuse => {
  s[purpuse.name] = purpuse.description;
});
   this.purpuseSelection =  s;
} 

@action  environmentList() {
    let s:any = {};
this.environments.forEach(env => {
  s[env.name] = env.description;
});
   this.environmentSelection =  s;
} 
   @action centerList(){
       let s:any = {};
       this.centers.forEach(center=>{
           s[center.tag] = center.heb_name;
       });

       this.centerSelection = s;
   }
   @action centerTypeList(){
    let s:any = {};
    this.centerTypes.forEach(centerType=>{
        s[centerType.type] = centerType.description;
    })
    this.centerTypeSelection = s;
}
    @action changeDBPassword = (key:string , value:string) => {
        let system = this.systems.find(x => x.name === key);
        if(system)
        system.dbPassword = value;
    }
    @action openSystemSnackbar()
    {
        this.systemSnackbarOpen = true;
    }
    @action closeSystemSnackbar(){
        this.systemSnackbarOpen = false;
        this.systemSnackbarMessage = '';
    }

    @action openPersonSnackbar()
    {
        this.personSnackbarOpen = true;
    }
    @action closePersonSnackbar(){
        this.personSnackbarOpen = false;
        this.personSnackbarMessage = '';
    }
    @action setCenterDesc()
    {
        const userCenter = this.centers.find(x=> x.tag === this.user_group)
        if(userCenter)
        this.centerDesc = `- ${userCenter.heb_name}`
    }
//------------------------------SYSTEMS CRUDS-----------------------------------------------------------
    @action async getSystems()
{
    const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
    const myInit = { // OPTIONAL
         headers: {jwt : token}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        };
    const path = '/systems'
    API.get('SystemsAPI',path,myInit).then(res=>{
        let tempSystems:any[] = res.data
        tempSystems.forEach((system:any) => {
            if(!system.purpuse)
            {
                system.purpuse = [];
            }
            if(typeof(system.purpuse) === 'string')
            {
                let purpuse:string[] = [];
                purpuse.push(system.purpuse);
                system.purpuse = purpuse;
            }    
        });
        this.systems = tempSystems;

    }).catch(err =>{
        alert("err");
        console.log(JSON.stringify(err));
    });

    }
    @action async updateSystem(oldSystem:System , newSystem:System)
    {
        let valid = true;
        if(newSystem.name !== oldSystem.name)
        {
            if(this.systems.findIndex(x => (x.center === newSystem.center&&x.name === newSystem.name)) !== -1)
            {
                valid  = false;
                this.systemSnackbarMessage = "System with the same name already exist";
               this.openSystemSnackbar();
            }
        }
        if(valid)
        {
        if(this.validateSystem(newSystem))
        {
        const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        let sessionIdInfo:any = jwtDecode(token);
        let username = sessionIdInfo['username'];
        const event = new Date();
        const day = this.formatDate(event.getDate());
          const month = this.formatDate(event.getMonth()+1);
          const year = event.getFullYear();
          const hour = this.formatDate(event.getHours());
          const minute = this.formatDate(event.getMinutes());
          const seconds = this.formatDate(event.getSeconds());
          const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
          newSystem.lastChangeEmail = username;
          newSystem.lastChangeDate = date;
          if(!newSystem.purpuse)
         {
             newSystem.purpuse = [];
         }
        // tempSys
        const systemIndex:number = this.systems.findIndex(x => (x.center === oldSystem.center&&x.name === oldSystem.name));
        //alert(systemIndex);
        let tempSystems = this.systems.slice();
        tempSystems[systemIndex] = newSystem;
        this.systems = tempSystems;
        // const insertSystem = {center: newSystem.center , 
        //     name: newSystem.name ,
        //     responsible : newSystem.responsible,
        //     enviroment: newSystem.enviroment,
        //     purpuse :newSystem.purpuse,
        //     dbAdmin : newSystem.dbAdmin,
        //     dbPassword: newSystem.dbPassword,
        //     ipAddress : newSystem.ipAddress,
        //     oracleSid : newSystem.oracleSid,
        //     osAdmin : newSystem.osAdmin, 
        //     osPassword : newSystem.osPassword,
        //     lastChangeEmail : username,
        //     lastChangeDate : time
        // }
        const myInit = { // OPTIONAL
            headers: {jwt : token}, // OPTIONAL
           response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
           body : newSystem
           };
       const path = '/systems'
       API.post('SystemsAPI',path,myInit).then(res=>{
          //  alert('updated');
       }).catch(err=>{
         alert(err);
       });
    }
    }
    }
    @action async deleteSystem(oldSystem:System)
    {
        const systemIndex:number = this.systems.findIndex(x => (x.center === oldSystem.center&&x.name === oldSystem.name));
        // alert(systemIndex);
        let tempSystems = this.systems.slice();
        tempSystems.splice(systemIndex,1);
        //tempSystems[systemIndex] = newSystem;
        this.systems = tempSystems;
        const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        const myInit = { // OPTIONAL
            headers: {jwt : token}, // OPTIONAL
           response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
           body: oldSystem
           };
       //const path = `/systems/object/${oldSystem.center}/${oldSystem.name}`;
       const path = '/systems';
       API.del('SystemsAPI',path,myInit).then(res=>{
          //  alert('deleted');
       }).catch(err=>{
         alert(err);
       });
    }

     @action formatDate(date:number){
         return date > 9 ? date.toString() : "0" + date;
     }

    
    @action async addSystem(newSystem:System)
    {
        if(!this.user_group)
        {
            alert ("please log out");
        }
        // const systemIndex:number = this.systems.findIndex(x => x.name == oldSystem.name);
        // alert(systemIndex);
        else
        {
            if(this.user_group !== 'DGMC')
            {
                newSystem.center = this.user_group;
            }
         if(this.systems.findIndex(x => (x.center === newSystem.center&&x.name === newSystem.name)) !== -1)
         {
            this.systemSnackbarMessage = "system with the same name already exist";
            this.openSystemSnackbar();
         }
         else if(this.validateSystem(newSystem))
         {
          const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
          let sessionIdInfo:any = jwtDecode(token);
          let username = sessionIdInfo['username'];
          const event = new Date();
          const day = this.formatDate(event.getDate());
          const month = this.formatDate(event.getMonth()+1);
          const year = event.getFullYear();
          const hour = this.formatDate(event.getHours());
          const minute = this.formatDate(event.getMinutes());
          const seconds = this.formatDate(event.getSeconds());
          const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
          newSystem.lastChangeEmail = username;
          newSystem.lastChangeDate = date;
         let tempSystems = this.systems.slice();
         alert(newSystem.purpuse);
         if(!newSystem.purpuse)
         {
             newSystem.purpuse = [];
         }
        newSystem.showPassword = false;
        tempSystems.push(newSystem);
        this.systems = tempSystems;
        const myInit = { // OPTIONAL
            headers: {jwt : token}, // OPTIONAL
           response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
           body : newSystem
           };
       const path = '/systems'
       API.post('SystemsAPI',path,myInit).then(res=>{
           // alert('inserted');
       }).catch(err=>{
         alert(err);
       });
    }
}
    }
    //------------------------------SYSTEMS CRUDS END-----------------------------------------------------------
    @action async getGroup(){
        this.user_group = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload["cognito:groups"][0];
    }
   //-------------------------------PERSONS CRUDS----------------------------------------------------------------
    @action async getPersons(){
        const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        const myInit = { // OPTIONAL
             headers: {jwt : token}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };
        const path = '/persons'
        API.get('PersonsAPI',path,myInit).then(res=>{
            let tempPersons = res.data;
            this.persons = tempPersons;
            this.personList();
        }).catch(err =>{
            alert("err");
            console.log(JSON.stringify(err));
        });
    }
    @action async addPerson(newPerson:Person){
        if(!this.user_group)
        {
            alert ("please log out");
        }
        // const systemIndex:number = this.systems.findIndex(x => x.name == oldSystem.name);
        // alert(systemIndex);
        else
        {
            if(this.user_group !== 'DGMC')
            {
                newPerson.center = this.user_group;
            }
            // if(this.user_group == 'shamir')
            // {
            //     newPerson.center = 'shamir';
            // }
            if(this.persons.findIndex(x => (x.center === newPerson.center&&x.email === newPerson.email)) !== -1)
            {
               this.personSnackbarMessage = "Person with the same email already exist";
               this.openPersonSnackbar();
            }
        else if(this.validatePerson(newPerson))
        {
            const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
            let sessionIdInfo:any = jwtDecode(token);
            let username = sessionIdInfo['username'];
            const event = new Date();
            const day = this.formatDate(event.getDate());
            const month = this.formatDate(event.getMonth()+1);
            const year = event.getFullYear();
            const hour = this.formatDate(event.getHours());
            const minute = this.formatDate(event.getMinutes());
            const seconds = this.formatDate(event.getSeconds());
            const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
            newPerson.lastChangeEmail = username;
            newPerson.lastChangeDate = date;
         let tempPersons = this.persons.slice();
        // tempSystems[systemIndex] = newSystem;
        tempPersons.push(newPerson);
        this.persons = tempPersons;
        this.personList();
        // const insertPer = {center: newSystem.center , 
        //     name: newSystem.name ,
        //     responsible : newSystem.responsible,
        //     enviroment: newSystem.enviroment,
        //     purpuse :newSystem.purpuse,
        //     dbAdmin : newSystem.dbAdmin,
        //     dbPassword: newSystem.dbPassword,
        //     ipAddress : newSystem.ipAddress,
        //     oracleSid : newSystem.oracleSid,
        //     osAdmin : newSystem.osAdmin, 
        //     osPassword : newSystem.osPassword
        // }
        const myInit = { // OPTIONAL
            headers: {jwt : token}, // OPTIONAL
           response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
           body : newPerson
           };
       const path = '/persons'
       API.post('PersonsAPI',path,myInit).then(res=>{
            //alert('inserted');
       }).catch(err=>{
         alert(err);
       });
    }
    }
    }
    @action async updatePerson(oldPerson:Person,newPerson:Person)
    {
        let valid = true;
        if(newPerson.email !== oldPerson.email)
        {
            if(this.persons.findIndex(x => (x.center === newPerson.center&&x.email === newPerson.email)) !== -1)
            {
                valid  = false;
                this.personSnackbarMessage = "Person with the same email already exist";
               this.openPersonSnackbar();
            }
        }
        if(valid)
        {
        if(this.validatePerson(newPerson))
        {
        const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        let sessionIdInfo:any = jwtDecode(token);
        let username = sessionIdInfo['username'];
        const event = new Date();
        const day = this.formatDate(event.getDate());
        const month = this.formatDate(event.getMonth()+1);
        const year = event.getFullYear();
        const hour = this.formatDate(event.getHours());
        const minute = this.formatDate(event.getMinutes());
        const seconds = this.formatDate(event.getSeconds());
        const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
        newPerson.lastChangeEmail = username;
        newPerson.lastChangeDate = date;
        const personIndex:number = this.persons.findIndex(x => (x.center === oldPerson.center&&x.email === oldPerson.email));
        let tempPersons = this.persons.slice();
        tempPersons[personIndex] = newPerson;
        this.persons = tempPersons;
        this.personList();
        const myInit = { // OPTIONAL
            headers: {jwt : token}, // OPTIONAL
           response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
           body : newPerson
           };
       const path = '/persons'
       API.post('PersonsAPI',path,myInit).then(res=>{
          //  alert('updated');
       }).catch(err=>{
         alert(err);
       });
    }
    }
    }
    @action async deletePerson(oldPerson:Person){
        const personsIndex:number = this.persons.findIndex(x => (x.center === oldPerson.center && x.email === oldPerson.email));
        // alert(systemIndex);
        let tempPersons = this.persons.slice();
        tempPersons.splice(personsIndex,1);
        //tempSystems[systemIndex] = newSystem;
        this.persons = tempPersons;
        this.personList();
        const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        const myInit = { // OPTIONAL
            headers: {jwt : token}, // OPTIONAL
           response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
           };
       const path = `/persons/object/${oldPerson.center}/${oldPerson.email}`;
       API.del('PersonsAPI',path,myInit).then(res=>{
          //  alert('deleted');
       }).catch(err=>{
         alert(err);
       });
    }
     //-------------------------------PERSONS CRUDS END----------------------------------------------------------------
     //-------------------------------CENTERS CRUDS -------------------------------------------------------------------
     @action async addCenter(newCenter:Center){
        if(!this.user_group)
        {
            alert ("please log out");
        }
        // const systemIndex:number = this.systems.findIndex(x => x.name == oldSystem.name);
        // alert(systemIndex);
        else
        {
        const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        let sessionIdInfo:any = jwtDecode(token);
        let username = sessionIdInfo['username'];
        const event = new Date();
        const day = this.formatDate(event.getDate());
        const month = this.formatDate(event.getMonth()+1);
        const year = event.getFullYear();
        const hour = this.formatDate(event.getHours());
        const minute = this.formatDate(event.getMinutes());
        const seconds = this.formatDate(event.getSeconds());
        const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
        newCenter.lastChangeEmail = username;
        newCenter.lastChangeDate = date;
         let tempCenter = this.centers.slice();
        // tempSystems[systemIndex] = newSystem;
        tempCenter.push(newCenter);
        this.centers = tempCenter;
        this.centerList();
        // const insertPer = {center: newSystem.center , 
        //     name: newSystem.name ,
        //     responsible : newSystem.responsible,
        //     enviroment: newSystem.enviroment,
        //     purpuse :newSystem.purpuse,
        //     dbAdmin : newSystem.dbAdmin,
        //     dbPassword: newSystem.dbPassword,
        //     ipAddress : newSystem.ipAddress,
        //     oracleSid : newSystem.oracleSid,
        //     osAdmin : newSystem.osAdmin, 
        //     osPassword : newSystem.osPassword
        // }
        const myInit = { // OPTIONAL
            headers: {jwt : token}, // OPTIONAL
           response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
           body : newCenter
           };
       const path = '/centers'
       API.post('centersAPI',path,myInit).then(res=>{
            //alert('inserted');
       }).catch(err=>{
         alert(err);
       });
    }
    }
    @action async getCenters(){
         const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        const myInit = { // OPTIONAL
             headers: {jwt : token}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };
        const path = '/centers'
        // var sessionIdInfo:any = jwtDecode(token);
        // var user_groups = sessionIdInfo['cognito:groups'];
        // if (user_groups.includes('DGMC')) {
        API.get('centersAPI',path,myInit).then(res=>{
            let tempCenters = res.data;
            this.centers = tempCenters;
            this.centerList();
        }).catch(err =>{
            alert("err");
            console.log(JSON.stringify(err));
        });
    //}
    }   
    @action async deleteCenter(oldCenter:Center){
        const centersIndex:number = this.centers.findIndex(x => (x.tag === oldCenter.tag ));
        // alert(systemIndex);
        let tempCenters = this.centers.slice();
        tempCenters.splice(centersIndex,1);
        //tempSystems[systemIndex] = newSystem;
        this.centers = tempCenters;
        this.centerList();
        const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        const myInit = { // OPTIONAL
            headers: {jwt : token}, // OPTIONAL
           response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
           };
       const path = `/centers/object/${oldCenter.tag}`;
       API.del('centersAPI',path,myInit).then(res=>{
          //  alert('deleted');
       }).catch(err=>{
         alert(err);
       });
    }
  
    @action async updateCenter(oldCenter:Center,newCenter:Center)
    {
        const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        let sessionIdInfo:any = jwtDecode(token);
        let username = sessionIdInfo['username'];
        const event = new Date();
        const day = this.formatDate(event.getDate());
        const month = this.formatDate(event.getMonth()+1);
        const year = event.getFullYear();
        const hour = this.formatDate(event.getHours());
        const minute = this.formatDate(event.getMinutes());
        const seconds = this.formatDate(event.getSeconds());
        const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
        newCenter.lastChangeEmail = username;
        newCenter.lastChangeDate = date;
        const centerIndex:number = this.centers.findIndex(x => (x.tag === oldCenter.tag));
        let tempCenters = this.centers.slice();
        tempCenters[centerIndex] = newCenter;
        this.centers = tempCenters;
        this.centerList();
        const myInit = { // OPTIONAL
            headers: {jwt : token}, // OPTIONAL
           response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
           body : newCenter
           };
       const path = '/centers'
       API.post('centersAPI',path,myInit).then(res=>{
          //  alert('updated');
       }).catch(err=>{
         alert(err);
       });
    }
    @action validateSystem(system:System)
    {
        let message = '';
        let isValidated = true;
        let isIpvalid = true;
        if(!system.center)
        {
            message += 'you must fill the system center';
            isValidated = false;
        }
        if(!system.name)
        {
            if(isValidated)
            message += 'you must fill the system name';
            else
            message += ' , you must fill the system name'
            isValidated = false;
        }
        else
        {
        const name = system.name.replace(/\s/g, '');
        if(!name)
        {
            if(isValidated)
             message += 'you must fill the system name';
             else
             message += ' , you must fill the system name'
            isValidated = false;
        }
        }   
        if(!system.responsible)
        {
            if (isValidated)
            message += 'you must fill the responsible for the system';
            else
            message += ', you must fill the responsible for the system ';
            isValidated = false;
        }

        if(system.ipAddress)
        {
        const addresses = system.ipAddress.split(',');
        addresses.forEach(ip => {
            if(ip.startsWith(' '))
            {
                ip = ip.substr(1);
            }
            if(ip.endsWith(' '))
            {
                ip = ip.substr(0,ip.length-1);
            }
            if(!this.validateIp(ip))
            {
                isIpvalid = false;
            }
        });

        if(!isIpvalid)
        {
            if(isValidated)
            message +='ip address is illigal';
            else
            message +=' , ip address is illigal';
            isValidated = false;
        }
    }
        if(!isValidated)
        {
        this.systemSnackbarMessage = message;
        this.openSystemSnackbar();
        }

        return isValidated;
    }

    @action validatePerson(person:Person)
    {
        let message = '';
        let isValidated = true;
        if(!person.center)
        {
            message += 'you must fill the Person center\n';
            isValidated = false;
        }
        // const email = person.email.replace(/\s/g, '');
        // if(!email)
        // {
        //     message += 'you must fill the Person email\n';
        //     isValidated = false;
        // }
        if(!this.validateEmail(person.email))
        {
            message += 'illigal email address\n';
            isValidated = false;
        }

        if(person.firstname && !validateEnglishName(person.firstname))
        {
                 message += 'First Name and last Name Must be in english';
                 isValidated = false;
        } 
        
        if(person.lastname && !validateEnglishName(person.lastname))
        {

                 message += 'First Name and last Name Must be in english';
                 isValidated = false;
        }
        if(person.firstname_heb && !validateHebrewName(person.firstname_heb))
        {
                 message += 'Hebrew names must contain only Hebrew';
                 isValidated = false;
        }

        if(person.lastname_heb && !validateHebrewName(person.lastname_heb))
        {
                 message += 'Hebrew names must contain only Hebrew';
                 isValidated = false;
        }

        if(person.phone_number && !validateCellPhone(person.phone_number))
            {
                message += 'Phone Numer is illigal';
                 isValidated = false;
            }
            if(person.office_number && !validateOfficePhone(person.office_number))
            {
                message += 'office Phone Numer is illigal';
                isValidated = false;
            }
        if(!isValidated)
        {
        this.personSnackbarMessage = message;
        this.openPersonSnackbar();
        }

        return isValidated;
    }
    @action validateIp(ip:string)
    {   
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip))
             {
               return (true)
             }
           return (false)
           
    }
    @action validateEmail(email:string)
    {
        if(email.startsWith(' '))
        {
            email = email.substr(1);
        }
        if(email.endsWith(' '))
        {
            email = email.substr(0,email.length-1);
        }
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
            return (true)
        }
            return (false)
    }       
}


// export default createContext(new Store());