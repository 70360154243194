import React from 'react';
import MaterialTable, { Column } from 'material-table';
import { useObserver} from 'mobx-react-lite';
import {useStore} from '../helpers/use-store';
import { SupplierPerson } from '../interfaces';
import LastChangeModal from './lastChangeM';
import Typography from '@material-ui/core/Typography';
// interface Row {
//   name: string;
//   surname: string;
//   birthYear: number;
//   birthCity: number;
// }

// interface TableState {
//   columns: Array<Column<Customer>>;
//   data: Customer[];
// }

 export const SupplierPersonEdit = () => {
  let oraStore = useStore();
  // useEffect(() => {
  //   alert('before');
  // })
//   const handleClickShowPassword = (event:React.MouseEvent<HTMLButtonElement>) => {
//     oraStore.chanegPasswordState(event.currentTarget.accessKey);
//   };
//   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
//     event.preventDefault();
//   };
  // const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };
//   const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
//     oraStore.changeDBPassword(event.target.accessKey, event.target.value);
//   }
   
    let editable:any;
    let columns:Array<Column<SupplierPerson>>;
    let options:any = {paging:false,
        headerStyle: {
            backgroundColor: '#40caf7',
            color: '#FFF'}};
    
      columns = [
        // {title: 'Center', field:'center'},
        { title: 'Email' ,field: 'email' },
        { title: 'First Name', field: 'firstname' },
        {title: 'Last Name' , field: 'lastname'},
        {title:'Phone Number' , field: 'phone_number'},
        {title: 'Office Number' , field: 'office_number'}
        ]; 
        // {title:'Last Change' , tooltip:'last change record' , render: center =>
        // //    //<IconButton accessKey = {JSON.stringify({'type':'system','center':system.center ,'name': system.name})} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
        //    <IconButton accessKey = {center ? JSON.stringify({'type':'center','tag':center.tag}) : ''} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
        editable={
          onRowAdd: (newData:SupplierPerson) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                oraStore.supplierStore.addSupplierPerson(newData);
              }, 600);
            }),
          onRowUpdate: (newData:SupplierPerson, oldData:SupplierPerson) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                if (oldData) {
                    oraStore.supplierStore.updateSupplierPersons(oldData,newData);
                 }
                
              }, 600);
            }),
          onRowDelete: (oldData:SupplierPerson) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                oraStore.supplierStore.deleteSupplierPerson(oldData);
              }, 600);
            })  
        };
      // editable = {
      //   onRowUpdate: (newData:Center, oldData:Center) =>
      //     new Promise((resolve) => {
      //       setTimeout(() => {
      //         resolve();
      //         if (oldData) {
      //             oraStore.updateCenter(oldData,newData);
      //          }
              
      //       }, 600);
      //     }),
      // }
        
  //let suppliers = oraStore.supplierStore.supplierPersons.filter(x => x.supplier == oraStore.supplierStore.editKey);
  return useObserver(() =>oraStore.supplierStore.isEdit ? (
    <div>
    <MaterialTable
      title={<Typography variant="h5" > Edit Persons </Typography>}
      columns={columns}
      data={oraStore.supplierStore.filteredPersons}
      options={options}
      editable={editable}
    />
    <LastChangeModal/>
    </div>)
  : (<div>
  </div>));
}

