import React, {useEffect, useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import {useObserver} from 'mobx-react-lite';
import {useStore} from '../helpers/use-store';
import { System } from '../interfaces';
import Snackbar from '@material-ui/core/Snackbar';
import {Alert} from '@material-ui/lab';
import LastChangeModal from './lastChangeM';
import Typography from '@material-ui/core/Typography';
import {useWindowResize} from '../helpers/useWindowResize';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface Row {
  name: string;
  surname: string;
  birthYear: number;
  birthCity: number;
}

interface TableState {
  columns: Array<Column<System>>;
  data: System[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

 export const SystemsList = () => {
  const classes = useStyles();
  let oraStore = useStore();
  // useEffect(() => {
  //   alert('before');
  // })
  const handleClickShowPassword = (event:React.MouseEvent<HTMLButtonElement>) => {
    oraStore.chanegPasswordState(event.currentTarget.accessKey);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  // const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };
  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown}>,system:System) => {
    const temp:any = event.target.value;
    let tempSystem = system;
    tempSystem.purpuse = temp;
    system = tempSystem;
    setPurpuse(temp)
  };

  // const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const { options } = event.target as HTMLSelectElement;
  //   const value: string[] = [];
  //   for (let i = 0, l = options.length; i < l; i += 1) {
  //     if (options[i].selected) {
  //       value.push(options[i].value);
  //     }
  //   }
  //   //setPersonName(value);
  // };
  
  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    oraStore.changeDBPassword(event.target.accessKey, event.target.value);
  }
  // const getPersons:object = () =>{
  //   let s:any = {};
  //   oraStore.persons.forEach(person => {
  //     s[person.firstname] = person.firstname;
  //   });
  //    alert(JSON.stringify(s));
  //    return s;
  const [row, setPurpuse] = useState<string[]|undefined>();
    const emptyArray:string[] = [];
    const { height } = useWindowResize();
    //const headerHeight = 0;
    let options:any = {paging:false,
      headerStyle: {
        backgroundColor: '#40caf7',
        color: '#FFF',position: 'sticky',
         top: 0 },maxBodyHeight: height - 128}; 
      
      let editable:any = {
        onRowAdd: (newData:System) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              oraStore.addSystem(newData);
            }, 600);
          }),
        onRowUpdate: (newData:System, oldData:System) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              if (oldData) {
                  oraStore.updateSystem(oldData,newData);
                 
               }
            }, 600);
          }),
        onRowDelete: (oldData:System) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              oraStore.deleteSystem(oldData);
            }, 600);
          })  
      };
      let columns:Array<Column<System>> = [
      // {title: 'Center', field:'center'},
      { title: 'System Name', field: 'name',filtering: false },
      { title: 'Responsible',tooltip:"האחראי מטעם בית החולים לאשר פעולות על המערכת", field: 'responsible', lookup:oraStore.personSelection},
      { title: 'DB SID', field: 'oracleSid',filtering: false },
       { title: 'IP Addresses' , field: 'ipAddress',tooltip:'for multiple ips use a comma(,)',filtering: false},
       { title: 'Environment' , 
        field: 'enviroment',
         lookup:oraStore.environmentSelection
      },
      //render: system => system.enviroment ? <Chip key={system.enviroment} label={system.enviroment} className={classes.chip} /> : ''
      // {title: 'Purpuse' , field: 'purpuse' , lookup:oraStore.purpuseSelection},
      {title: 'Purpuse' , field : 'purpuse' , render: system => 
      <Select
      labelId="chip-select"
      id="chip-select"
      multiple
      disabled={true}
      value={system ? system.purpuse : emptyArray }
      onChange={(event) => handleSelectChange(event,system)}
      input={<Input id="select-multiple-chip" />}
      renderValue={(selected) => (
        <div className={classes.chips}>
          {(selected as string[]).map((value) => (
            <Chip key={value} label={value} className={classes.chip} />
          ))}
        </div>
      )}
      MenuProps={MenuProps}
    >
      {oraStore.purpuses.map((purpuse) => (
        <MenuItem key={purpuse.name} value={purpuse.name}>
          {purpuse.description}
        </MenuItem>
      ))}
    </Select> , editComponent: system =>
    <Select
    labelId="demo-mutiple-chip-label"
    id="demo-mutiple-chip"
    multiple
    disabled={false}
    value={system.rowData.purpuse ? system.rowData.purpuse : emptyArray }
    // onChange={(event) => handleSelectChange(event,system.rowData)}
    input={<Input id="select-multiple-chip" />}
    renderValue={(selected) => (
      <div className={classes.chips}>
        {(selected as string[]).map((value) => (
          <Chip key={value} label={value} className={classes.chip} />
        ))}
      </div>
    )}
    MenuProps={MenuProps}
  >
     <FormGroup>
    {oraStore.purpuses.map((purpuse) => (
      <div>
       <FormControlLabel
            control={<Checkbox checked=
              {system.rowData.purpuse ? system.rowData.purpuse.findIndex(x=> x === purpuse.name) !== -1 : false } 
              name={purpuse.name} onChange={(event) => handleCheckChange(event,system.rowData)} />}
            label={purpuse.description}
          />
      </div>
    ))}
    <h1 hidden={true} > {row} </h1> 
    </FormGroup>
  </Select>},

      {title:'OS user' , field: 'osAdmin',filtering: false},
      {title:'OS Password', field: 'osPassword' ,searchable:false,sorting:false ,export: false , filtering: false ,render: system  => <Input
      id="standard-adornment-password"
      type={system.showPassword ? 'text' : 'password'}
      value={system.osPassword}
      onChange={handleChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
             onClick={handleClickShowPassword}
            accessKey = {system.name}
            onMouseDown={handleMouseDownPassword}
          >
            {system.showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />},
    {title:'DB user' , field: 'dbAdmin',filtering: false},
    {title: "DB Password" ,searchable:false,sorting:false,export:false ,filtering: false, field: 'dbPassword' , 
     render: system => 
     <Input
            id="standard-adornment-password"
            type={system.showPassword ? 'text' : 'password'}
            value={system.dbPassword}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                   onClick={handleClickShowPassword}
                  accessKey = {system.name}
                  onMouseDown={handleMouseDownPassword}
                >
                  {system.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
    },
    {title:'Last Change' , tooltip:'last change record' , render: system =>
    //    //<IconButton accessKey = {JSON.stringify({'type':'system','center':system.center ,'name': system.name})} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
       <IconButton accessKey = {system ? JSON.stringify({'type':'system','center':system.center ,'name': system.name}) : ''} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
    ]; 
    if (oraStore.user_group === 'DGMC' || oraStore.user_group === 'DGMC-RO')
    {
      columns.unshift({title: 'Center', field:'center' , lookup:oraStore.centerSelection });
      options.exportButton = true;
      options.exportFileName = "Systems"
      // options = {paging:false,filtering:true};
    }

    if (oraStore.user_group === 'DGMC-RO')
    {
      editable = {};
    }
    // function Alert(props: AlertProps) {
    //   return <MuiAlert elevation={6} variant="filled" {...props} />;
    // }
    
    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>,system:System) => {
      if(!system.purpuse)
      {
        system.purpuse = [];
      }
      if(event.target.checked)
      {
        let temp = system.purpuse.slice();
        temp.push(event.target.name);
        system.purpuse = temp;
      }
      else
      {
        system.purpuse = system.purpuse.filter(x=>x!==event.target.name);
      }
      setPurpuse(system.purpuse);
    };
    useEffect(()=>{
        if((oraStore.user_group)&&(oraStore.centers)&&(!oraStore.centerDesc))
        {
          oraStore.setCenterDesc();
        }
    });
    const handleInfoClick = (event:React.MouseEvent<HTMLButtonElement>) => {
      oraStore.setModal(event.currentTarget.accessKey);
      oraStore.openChangeModal();
    }
  return useObserver(() =>(
    <div>
    <MaterialTable
      title={<Typography variant="h5" > Systems List {oraStore.centerDesc} </Typography>}
      columns={columns}
      data={oraStore.systems}
      options={options}
      editable={editable}
    /> 
    <div>
 <LastChangeModal/>
 <Snackbar open={oraStore.systemSnackbarOpen} autoHideDuration={6000} onClose={oraStore.closeSystemSnackbar}>
  <Alert onClose={oraStore.closeSystemSnackbar} severity="error">
     {oraStore.systemSnackbarMessage}
  </Alert>
 </Snackbar>
 </div>
 <h1 hidden={true} >{JSON.stringify(oraStore.centerSelection)} , {JSON.stringify(oraStore.personSelection) } ,  </h1> 
</div>
  ));
}

