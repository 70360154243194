import React from 'react';
import MaterialTable, { Column } from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useObserver} from 'mobx-react-lite';
import {useStore} from '../helpers/use-store';
import { Supplier } from '../interfaces';
import LastChangeModal from './lastChangeM';
import {SupplierPersons} from './supplierPersons';
import {SupplierPersonEdit} from './editSuppliers';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import {Alert} from '@material-ui/lab';
// interface Row {
//   name: string;
//   surname: string;
//   birthYear: number;
//   birthCity: number;
// }

// interface TableState {
//   columns: Array<Column<Customer>>;
//   data: Customer[];
// }

 export const SupplierList = () => {
  let oraStore = useStore();
  // useEffect(() => {
  //   alert('before');
  // })
//   const handleClickShowPassword = (event:React.MouseEvent<HTMLButtonElement>) => {
//     oraStore.chanegPasswordState(event.currentTarget.accessKey);
//   };
//   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
//     event.preventDefault();
//   };
  // const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };
//   const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
//     oraStore.changeDBPassword(event.target.accessKey, event.target.value);
//   }
   
    let editable:any;
    let columns:Array<Column<Supplier>>;
    // const { height } = useWindowResize();
    // const headerHeight = 0;
    // let options:any = {paging:false,
    //   headerStyle: {
    //     backgroundColor: '#40caf7',
    //     color: '#FFF',position: 'sticky',
    //      top: 0 },maxBodyHeight: height - 64};

         let options:any = {paging:false,
          headerStyle: {
            backgroundColor: '#40caf7',
            color: '#FFF',
             top: 0 }}; 
       // let tableRef = createRef();
    const editSupplierList = (event:React.MouseEvent<HTMLButtonElement>) => {
        
        oraStore.supplierStore.enableEdit(event.currentTarget.accessKey);
        // tableRef.current.
    }
    if(oraStore.user_group !== 'DGMC')
    {
      columns = [
        // {title: 'Center', field:'center'},
        { title: 'Name' ,field: 'name' },
        { title: 'Address', field: 'address' }
        ]; 
        // {title:'Last Change' , tooltip:'last change record' , render: center =>
        // //    //<IconButton accessKey = {JSON.stringify({'type':'system','center':system.center ,'name': system.name})} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
        //    <IconButton accessKey = {center ? JSON.stringify({'type':'center','tag':center.tag}) : ''} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
 
        editable={};
      // editable = {
      //   onRowUpdate: (newData:Center, oldData:Center) =>
      //     new Promise((resolve) => {
      //       setTimeout(() => {
      //         resolve();
      //         if (oldData) {
      //             oraStore.updateCenter(oldData,newData);
      //          }
              
      //       }, 600);
      //     }),
      // }
    }
    else
    {
       columns = [
        // {title: 'Center', field:'center'},
        { title: 'Tag', field: 'tag' },
        { title: 'Name' ,field: 'name' },
        { title: 'Address', field: 'address' },
        {title:'Edit Persons' ,  render: supplier =>
        //    //<IconButton accessKey = {JSON.stringify({'type':'system','center':system.center ,'name': system.name})} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
        <IconButton accessKey = {supplier ? supplier.tag : ''} onClick={editSupplierList}>{supplier ? ( supplier.isEdit ? <CloseIcon/> : <EditIcon/>) : '' }  </IconButton>}
        ]
      options.exportButton = true;
      options.exportFileName = "Suppliers"
      editable =  {
        onRowAdd: (newData:Supplier) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              oraStore.supplierStore.addSupplier(newData);
            }, 600);
          }),
        onRowUpdate: (newData:Supplier, oldData:Supplier) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              if (oldData) {
                  oraStore.supplierStore.updateSupplier(oldData,newData);
               }
              
            }, 600);
          }),
        onRowDelete: (oldData:Supplier) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              oraStore.supplierStore.deleteSupplier(oldData);
            }, 600);
          })  
      }
     }
    const handleInfoClick = (event:React.MouseEvent<HTMLButtonElement>) => {
      oraStore.setModal(event.currentTarget.accessKey);
      oraStore.openChangeModal();
    }
  return useObserver(() =>(
    <div>
    <MaterialTable
      // tableRef = {tableRef}
      detailPanel={rowData => { return(<div><SupplierPersons supplier={rowData.tag} /></div>)}}
      title={<Typography variant="h5" > Suppliers List {oraStore.centerDesc} </Typography>}
      columns={columns}
      data={oraStore.supplierStore.suppliers}
      options={options}
      editable={editable}
    />
    <SupplierPersonEdit />
    <Snackbar open={oraStore.supplierStore.supplierSnackbarOpen} autoHideDuration={6000} onClose={oraStore.closeSystemSnackbar}>
  <Alert onClose={oraStore.supplierStore.closeSupplierSnackbar} severity="error">
     {oraStore.supplierStore.supplierSnackbarMessage}
  </Alert>
 </Snackbar>
    <LastChangeModal/>
    </div>  
  ));
}

