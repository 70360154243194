import React, {useEffect } from 'react';
import MaterialTable, { Column } from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { useObserver} from 'mobx-react-lite';
import {useStore} from '../helpers/use-store';
import { Person } from '../interfaces';
import Snackbar from '@material-ui/core/Snackbar';
import {Alert} from '@material-ui/lab';
import LastChangeModal from './lastChangeM';
import Typography from '@material-ui/core/Typography';
import {useWindowResize} from '../helpers/useWindowResize';

// interface Row {
//   name: string;
//   surname: string;
//   birthYear: number;
//   birthCity: number;
// }

// interface TableState {
//   columns: Array<Column<Customer>>;
//   data: Customer[];
// }

 export const PersonsList = () => {
  let oraStore = useStore();
  // useEffect(() => {
  //   alert('before');
  // })
//   const handleClickShowPassword = (event:React.MouseEvent<HTMLButtonElement>) => {
//     oraStore.chanegPasswordState(event.currentTarget.accessKey);
//   };

//   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
//     event.preventDefault();
//   };
  // const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };
//   const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
//     oraStore.changeDBPassword(event.target.accessKey, event.target.value);
//   }
    const { height } = useWindowResize();
    //const headerHeight = 0;
    let options:any = {paging:false,
      headerStyle: {
        backgroundColor: '#40caf7',
        color: '#FFF',position: 'sticky',
        top: 0 },maxBodyHeight: height - 128}; 
    let editable:any = {
      onRowAdd: (newData:Person) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            oraStore.addPerson(newData);
          }, 600);
        }),
      onRowUpdate: (newData:Person, oldData:Person) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            if (oldData) {
                oraStore.updatePerson(oldData,newData);
             }
            
          }, 600);
        }),
      onRowDelete: (oldData:Person) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            oraStore.deletePerson(oldData);
          }, 600);
        })  
    }
    let columns:Array<Column<Person>> = [
      // {title: 'Center', field:'center'},
      { title: 'Email', field: 'email',filtering: false },
      { title: 'First Name(Eng)', field: 'firstname',filtering: false },
      { title: 'Last Name(Eng)', field: 'lastname' ,filtering: false },
      { title: 'First Name(heb)' , field: 'firstname_heb' ,filtering: false},
      { title: 'Last Name(heb)' , field: 'lastname_heb' ,filtering: false},
      {title: 'Role' , field: 'role' , lookup:oraStore.roleSelection,},
     // {title:'Role(Heb)' , field: 'role_heb'},
      {title:'Mobile Number' , field:'phone_number' ,filtering: false},
      {title:'Office Number', field:'office_number' ,filtering: false},
      {title:'Rimini Authorized',field:'remini_authorized' , type:"boolean" ,tooltip: 'האם בעל התפקיד מורשה לגשת לאתר רמיני לצפיה ופתיחת פניות' },
      {title:'Rimini Approved' , field: 'remini_approved' , type:'boolean',editable:'never',initialEditValue:false},
      {title:'Last Change' , tooltip:'last change record' , render: person =>
    //    //<IconButton accessKey = {JSON.stringify({'type':'system','center':system.center ,'name': system.name})} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
       <IconButton accessKey = {person ? JSON.stringify({'type':'person','center':person.center ,'email': person.email}) : ''} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}

    ]; 
    if ((oraStore.user_group === 'DGMC') || (oraStore.user_group === 'DGMC-RO'))
    {
      columns.unshift({title: 'Center', field:'center',lookup:oraStore.centerSelection,filtering:true});
      const column =  columns.find(x=>x.title === 'Rimini Approved');
      if(column)
      column.editable = 'always';
      options.exportButton = true;
      options.exportFileName = "Persons"
      // options = {paging:false,filtering:true};
    }

    if(oraStore.user_group === 'DGMC-RO')
    {
      editable = {}
    }
    useEffect(()=>{
      if((oraStore.user_group)&&(oraStore.centers)&&(!oraStore.centerDesc))
      {
        oraStore.setCenterDesc();
      }
  });
  const handleInfoClick = (event:React.MouseEvent<HTMLButtonElement>) => {
    oraStore.setModal(event.currentTarget.accessKey);
    oraStore.openChangeModal();
  }
  return useObserver(() =>(
    <div>
    <MaterialTable 
      title={<Typography variant="h5" > Persons List {oraStore.centerDesc} </Typography>}
      columns={columns}
      data={oraStore.persons}
      options={options}
      editable={editable}
    />
        <div>
 <LastChangeModal/>
 <Snackbar open={oraStore.personSnackbarOpen} autoHideDuration={6000} onClose={oraStore.closePersonSnackbar}>
  <Alert onClose={oraStore.closePersonSnackbar} severity="error">
     {oraStore.personSnackbarMessage}
  </Alert>
 </Snackbar>
 </div>
    <h1 hidden={true} >{JSON.stringify(oraStore.centerSelection)} </h1>
    </div>
  ));
}

