import { observable, action, computed} from "mobx"
import {Supplier,SupplierPerson} from '../interfaces'
import {API,Auth} from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import Store from './store';
export default class SuplierStore {
    store:Store;
    //@observable suppliers:Supplier[] = [{tag:'elad' , name: "Elad Systems" , address: "somewhere",isEdit:false,lastChangeEmail:'',lastChangeDate:''},{tag:'sof' , name:"Softov" , address:"petach tiqwa",isEdit:false,lastChangeEmail:'',lastChangeDate:''}];
    @observable suppliers:Supplier[] = [];
    @observable supplierPersons:SupplierPerson[] = [];
    @observable isEdit = false;
    @observable editKey = '';
    @observable supplierSnackbarMessage = '';
    @observable supplierSnackbarOpen = false;
    constructor(store:Store) {
        this.store = store;
        this.closeSupplierSnackbar = this.closeSupplierSnackbar.bind(this);
        //this.filteredPersons = this.filteredPersons.bind(this);
    }

    @computed get filteredPersons(){
        return this.supplierPersons.filter(x => x.supplier === this.editKey);
    }
    @action enableEdit(supplier:string)
    {
        let tempSupplier = this.suppliers.slice();
        let supp = tempSupplier.find(x => x.tag === supplier);
        if(supp)
        {
         if((!supp.isEdit)&&(this.isEdit))
         {
            let prevSuplier = tempSupplier.find(x => x.tag === this.editKey);
            if(prevSuplier)
            {
              prevSuplier.isEdit = false;
            }
            this.isEdit = false;
         }
        supp.isEdit = !supp.isEdit;
        }
        
        this.editKey = supplier;
        this.isEdit = !this.isEdit;   
        this.suppliers = tempSupplier;
    }
    @action openSupplierSnackbar()
    {
        this.supplierSnackbarOpen = true;
    }
    @action closeSupplierSnackbar(){
        this.supplierSnackbarOpen = false;
        this.supplierSnackbarMessage = '';
    }

    @action async getSuppliers()
    {
        const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
        const myInit = { // OPTIONAL
             headers: {jwt : token}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };
        const path = '/supplier'
        API.get('SuppliersAPI',path,myInit).then(res=>{
            let tempSuppliers = res.data
            this.suppliers = tempSuppliers;
    
        }).catch(err =>{
            alert("err");
            console.log(JSON.stringify(err));
        });
    
        }
        @action async updateSupplier(oldSupplier:Supplier , newSupplier:Supplier)
        {
            let valid = true;
            if(newSupplier.tag !== oldSupplier.tag)
            {
                    valid  = false;
                    this.supplierSnackbarMessage = "System with the same name already exist";
                   this.openSupplierSnackbar();
                
            }
            if(valid)
            {
            const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
            let sessionIdInfo:any = jwtDecode(token);
            let username = sessionIdInfo['username'];
            const event = new Date();
            const day = this.formatDate(event.getDate());
              const month = this.formatDate(event.getMonth()+1);
              const year = event.getFullYear();
              const hour = this.formatDate(event.getHours());
              const minute = this.formatDate(event.getMinutes());
              const seconds = this.formatDate(event.getSeconds());
              const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
              newSupplier.lastChangeEmail = username;
              newSupplier.lastChangeDate = date;
              newSupplier.isEdit = false;
            const supplierIndex:number = this.suppliers.findIndex(x => (x.tag === oldSupplier.tag));
            //alert(systemIndex);
            let tempSuppliers = this.suppliers.slice();
            tempSuppliers[supplierIndex] = newSupplier;
            this.suppliers = tempSuppliers;
            const myInit = { // OPTIONAL
                headers: {jwt : token}, // OPTIONAL
               response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
               body : newSupplier
               };
           const path = '/supplier'
           API.post('SuppliersAPI',path,myInit).then(res=>{
              //  alert('updated');
           }).catch(err=>{
             alert(err);
           });
        }
        }
        @action async deleteSupplier(oldSupplier:Supplier)
        {
            const supplierIndex:number = this.suppliers.findIndex(x => (x.tag === oldSupplier.tag));
            // alert(systemIndex);
            let tempSuppliers = this.suppliers.slice();
            tempSuppliers.splice(supplierIndex,1);
            //tempSystems[systemIndex] = newSystem;
            this.suppliers = tempSuppliers;
            const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
            const myInit = { // OPTIONAL
                headers: {jwt : token}, // OPTIONAL
               response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
               body: oldSupplier
               };
           //const path = `/systems/object/${oldSystem.center}/${oldSystem.name}`;
           const path = '/supplier';
           API.post('SuppliersAPI',path,myInit).then(res=>{
              //  alert('deleted');
           }).catch(err=>{
             alert(err);
           });
        }
    
         @action formatDate(date:number){
             return date > 9 ? date.toString() : "0" + date;
         }
    
        
        @action async addSupplier(newSupplier:Supplier)
        {
            if(!this.store.user_group)
            {
                alert ("please log out");
            }
            // const systemIndex:number = this.systems.findIndex(x => x.name == oldSystem.name);
            // alert(systemIndex);
            else
            {
             if(this.suppliers.findIndex(x => (x.tag === newSupplier.tag)) !== -1)
             {
                this.supplierSnackbarMessage = "supplier with the same name already exist";
                this.openSupplierSnackbar();
             }
             else
             {
              const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
              let sessionIdInfo:any = jwtDecode(token);
              let username = sessionIdInfo['username'];
              const event = new Date();
              const day = this.formatDate(event.getDate());
              const month = this.formatDate(event.getMonth()+1);
              const year = event.getFullYear();
              const hour = this.formatDate(event.getHours());
              const minute = this.formatDate(event.getMinutes());
              const seconds = this.formatDate(event.getSeconds());
              const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
              newSupplier.lastChangeEmail = username;
              newSupplier.lastChangeDate = date;
              newSupplier.isEdit = false;
             let tempSuppliers = this.suppliers.slice();
            // tempSystems[systemIndex] = newSystem;
            tempSuppliers.push(newSupplier);
            this.suppliers = tempSuppliers;

            const myInit = { // OPTIONAL
                headers: {jwt : token}, // OPTIONAL
               response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
               body : newSupplier
               };
           const path = '/supplier'
           API.post('SuppliersAPI',path,myInit).then(res=>{
               // alert('inserted');
           }).catch(err=>{
             alert(err);
           });
        }
        
    }
        }

    
        @action async getSupplierPersons()
        {
            const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
            const myInit = { // OPTIONAL
                 headers: {jwt : token}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                };
            const path = '/person'
            API.get('SupplierPersonsAPI',path,myInit).then(res=>{
                let tempPersons = res.data
                this.supplierPersons = tempPersons;
        
            }).catch(err =>{
                alert("err");
                console.log(JSON.stringify(err));
            });
        
            }
            @action async updateSupplierPersons(oldPerson:SupplierPerson , newPerson:SupplierPerson)
            {
                let valid = true;
                if(newPerson.email !== oldPerson.email)
                  {
                     if(this.supplierPersons.findIndex(x => (x.supplier === newPerson.supplier&&x.email === newPerson.email)) !== -1)
                         {
                        valid  = false;
                        this.supplierSnackbarMessage = "Person with the same Email already exist";
                       this.openSupplierSnackbar();
                        }
                    
                }
                if(valid)
                {
                const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
                let sessionIdInfo:any = jwtDecode(token);
                let username = sessionIdInfo['username'];
                const event = new Date();
                const day = this.formatDate(event.getDate());
                  const month = this.formatDate(event.getMonth()+1);
                  const year = event.getFullYear();
                  const hour = this.formatDate(event.getHours());
                  const minute = this.formatDate(event.getMinutes());
                  const seconds = this.formatDate(event.getSeconds());
                  const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
                  newPerson.lastChangeEmail = username;
                  newPerson.lastChangeDate = date;
                const personIndex:number = this.supplierPersons.findIndex(x => (x.supplier === oldPerson.supplier && x.email === oldPerson.email));
                //alert(systemIndex);
                let tempPersons = this.supplierPersons.slice();
                tempPersons[personIndex] = newPerson;
                this.supplierPersons = tempPersons;
                const myInit = { // OPTIONAL
                    headers: {jwt : token}, // OPTIONAL
                   response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                   body : newPerson
                   };
               const path = '/person'
               API.post('SupplierPersonsAPI',path,myInit).then(res=>{
                  //  alert('updated');
               }).catch(err=>{
                 alert(err);
               });
            }
            }
            @action async deleteSupplierPerson(oldPerson:SupplierPerson)
            {
                const personIndex:number = this.supplierPersons.findIndex(x => (x.supplier === oldPerson.supplier && x.email === oldPerson.email));
                // alert(systemIndex);
                let tempPersons = this.supplierPersons.slice();
                tempPersons.splice(personIndex,1);
                //tempSystems[systemIndex] = newSystem;
                this.supplierPersons = tempPersons;
                const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
                const myInit = { // OPTIONAL
                    headers: {jwt : token}, // OPTIONAL
                   response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                   body: oldPerson
                   };
               //const path = `/systems/object/${oldSystem.center}/${oldSystem.name}`;
               const path = '/person';
               API.post('SupplierPersonsAPI',path,myInit).then(res=>{
                  //  alert('deleted');
               }).catch(err=>{
                 alert(err);
               });
            }
            @action async addSupplierPerson(newPerson:SupplierPerson)
            {
                if(!this.store.user_group)
                {
                    alert ("please log out");
                }
                // const systemIndex:number = this.systems.findIndex(x => x.name == oldSystem.name);
                // alert(systemIndex);
                else
                {
                 if(this.supplierPersons.findIndex(x => (x.supplier === newPerson.supplier && x.email === newPerson.email)) !== -1)
                 {
                    this.supplierSnackbarMessage = "supplier with the same name already exist";
                    this.openSupplierSnackbar();
                 }
                 else
                 {
                  const token = (await (Auth.currentSession())).getAccessToken().getJwtToken();
                  let sessionIdInfo:any = jwtDecode(token);
                  let username = sessionIdInfo['username'];
                  const event = new Date();
                  const day = this.formatDate(event.getDate());
                  const month = this.formatDate(event.getMonth()+1);
                  const year = event.getFullYear();
                  const hour = this.formatDate(event.getHours());
                  const minute = this.formatDate(event.getMinutes());
                  const seconds = this.formatDate(event.getSeconds());
                  const date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
                  newPerson.supplier = this.editKey;
                  newPerson.lastChangeEmail = username;
                  newPerson.lastChangeDate = date;
                 let tempPersons = this.supplierPersons.slice();
                // tempSystems[systemIndex] = newSystem;
                tempPersons.push(newPerson);
                this.supplierPersons = tempPersons;
    
                const myInit = { // OPTIONAL
                    headers: {jwt : token}, // OPTIONAL
                   response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                   body : newPerson
                   };
               const path = '/person'
               API.post('SupplierPersonsAPI',path,myInit).then(res=>{
                   // alert('inserted');
               }).catch(err=>{
                 alert(err);
               });
            }
            
        }
            }
}