/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:7530e6d1-cda2-4d0f-a485-33313477ec0f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_d2t3zu0qb",
    "aws_user_pools_web_client_id": "4ukmvaqj4vstv232r05u2647rt",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://0rskjm25l0.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "SystemsAPI",
            "endpoint": "https://rtn9s42rcc.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "PersonsAPI",
            "endpoint": "https://fdf4859ptc.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "centersAPI",
            "endpoint": "https://2zg3natyh8.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "SuppliersAPI",
            "endpoint": "https://i55gy9ritk.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "SupplierPersonsAPI",
            "endpoint": "https://t4f16bsv94.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "oracleDetailSites-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "Systems-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "PersonsTable-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "centersTable-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "OraRecorderLog-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "SuppliersTable-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "SupplierPersonsTable-dev",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "dgmc-oracledetails-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d7l07h7wbhkw7.cloudfront.net"
};


export default awsmobile;
