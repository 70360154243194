export function validateCellPhone(phone:string)
{
    const cellphone_regex:RegExp = new RegExp(/^(05|\+9725|9725)\d-?\d{3}-?\d{4}$/);
    return cellphone_regex.test(phone);
}

export function validateOfficePhone(phone:string)
{
    const phone_regex:RegExp = new RegExp(/^(02|03|04|05|08|09)-?\d-?\d{2}-?\d{4,5}$/);
    return phone_regex.test(phone);
}
export function validateHebrewName(name:string)
{
    const hebrew_name = new RegExp(/^[\u0590-\u05FF]+-?[\u0590-\u05FF]+$/);
    return hebrew_name.test(name);
}

export function validateEnglishName(name:string)
{
    const english_name = new RegExp(/^[a-zA-Z]+-?[a-zA-Z]+$/);
    return english_name.test(name);
}

export function validateIp(ip:string)
    {   
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip))
             {
               return (true)
             }
           return (false)
           
    }
    export function validateEmail(email:string)
    {
        if(email.startsWith(' '))
        {
            email = email.substr(1);
        }
        if(email.endsWith(' '))
        {
            email = email.substr(0,email.length-1);
        }
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
            return (true)
        }
            return (false)
    }       