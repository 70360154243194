import React from 'react';
import Amplify from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import PersistentDrawerLeft from './components/navigator';
Amplify.configure(awsconfig);
// const configurationOptions: any = {
//   signInConfig: {
//     isSignUpDisplayed: false
//   }
// };
function App() {
  return (
    // <AmplifyAuthenticator >
        <div>
          <PersistentDrawerLeft/>
        </div>
      // </AmplifyAuthenticator>
  );
}

export default withAuthenticator(App);
