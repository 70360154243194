import React,{useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {AmplifySignOut } from '@aws-amplify/ui-react';
import { Route, BrowserRouter as Router, Link } from 'react-router-dom';
import {SystemsList} from './listSystems';
import {PersonsList} from './listPersons';
import {CentersList} from './listCenters';
import {SupplierList} from './listSupplier';
import {useStore} from '../helpers/use-store';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background:'#40caf7',
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      //padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  let oraStore = useStore();
  const [open, setOpen] = React.useState(false);
  useEffect(()=>{
     oraStore.getGroup();
     if(oraStore.persons.length===0)
     oraStore.getPersons();
     if(oraStore.systems.length===0)
     oraStore.getSystems();
     if(oraStore.centers.length===0)
       oraStore.getCenters();
     if(oraStore.supplierStore.suppliers.length===0)
       oraStore.supplierStore.getSuppliers();
       if(oraStore.supplierStore.supplierPersons.length===0)
       oraStore.supplierStore.getSupplierPersons();
    //     window.addEventListener("beforeunload", (ev) => 
    // {  
    //   oraStore.signout();
    // });
  });
  const handleDrawerOpen = () => {
    setOpen(true);
  };

    // function handleMenuClick(e:MouseEvent<HTMLDivElement>){
    //   alert (e.currentTarget.accessKey);
    // }
  const handleDrawerClose = () => {
    setOpen(false);
  };
  let menuList = [{name:'systems' , text:'Systems'},{name:'persons' , text:'Persons'},{name:"centers",text:"Centers"},{name:'suppliers' , text: "Suppliers" }];
  // if (oraStore.user_group != 'DGMC')
  // {
  //   menuList.push({name:'suppliers' , text: "Suppliers" });
  // }
  return (
    <Router>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            OraRecorder - v1.3.1
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}  
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
        {menuList.map((item) => (
          <ListItem button accessKey={item.name} component={Link} to={"/" + item.name} onClick={handleDrawerClose}>
          <ListItemText primary={item.text} />
          </ListItem>
        ))}
          <ListItem> <AmplifySignOut /> </ListItem>
        </List>
        {/* <Divider /> */}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </div>
    <Route exact path="/" component = {SystemsList} />
          <Route exact path="/persons" component = {PersonsList} />
          <Route exact path="/systems" component = {SystemsList} />
          <Route exact path="/centers" component = {CentersList} />
          <Route exect path= "/suppliers" component = {SupplierList}/>
    </Router>
  );
}