import React from 'react';
import MaterialTable, { Column } from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import {  useObserver} from 'mobx-react-lite';
import {useStore} from '../helpers/use-store';
import { Center } from '../interfaces';
import InfoIcon from '@material-ui/icons/Info';
import LastChangeModal from './lastChangeM';
import Typography from '@material-ui/core/Typography';
import {useWindowResize} from '../helpers/useWindowResize';
// interface Row {
//   name: string;
//   surname: string;
//   birthYear: number;
//   birthCity: number;
// }

// interface TableState {
//   columns: Array<Column<Customer>>;
//   data: Customer[];
// }

 export const CentersList = () => {
  let oraStore = useStore();
  // useEffect(() => {
  //   alert('before');
  // })
//   const handleClickShowPassword = (event:React.MouseEvent<HTMLButtonElement>) => {
//     oraStore.chanegPasswordState(event.currentTarget.accessKey);
//   };
//   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
//     event.preventDefault();
//   };
  // const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };
//   const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
//     oraStore.changeDBPassword(event.target.accessKey, event.target.value);
//   }
   
    let editable:any;
    let columns:Array<Column<Center>>;
    const { height } = useWindowResize();
    //const headerHeight = 0;
    let options:any = {paging:false,
      headerStyle: {
        backgroundColor: '#40caf7',
        color: '#FFF',position: 'sticky',
         top: 0 },maxBodyHeight: height - 128}; 
    if(oraStore.user_group !== 'DGMC')
    {
      columns = [
        // {title: 'Center', field:'center'},
        { title: 'Name' ,field: 'heb_name' },
        { title: 'Type', field: 'type' , lookup:oraStore.centerTypeSelection},
        {title : 'Address(eng)' , field:'address'},
        {title: 'City(eng)' , field: 'city'} , 
        { title:'Address(heb)' , field: 'address_heb'},
        {title:'City(heb)' , field: 'city_heb'},
        {title:'Last Change' , tooltip:'last change record' , render: center =>
        //    //<IconButton accessKey = {JSON.stringify({'type':'system','center':system.center ,'name': system.name})} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
           <IconButton accessKey = {center ? JSON.stringify({'type':'center','tag':center.tag}) : ''} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
      ];  
        editable={};
      // editable = {
      //   onRowUpdate: (newData:Center, oldData:Center) =>
      //     new Promise((resolve) => {
      //       setTimeout(() => {
      //         resolve();
      //         if (oldData) {
      //             oraStore.updateCenter(oldData,newData);
      //          }
              
      //       }, 600);
      //     }),
      // }
    }
    else
    {
       columns = [
        // {title: 'Center', field:'center'},
        { title: 'Tag', field: 'tag' },
        { title: 'Name' ,field: 'heb_name' },
        { title: 'Type', field: 'type' , lookup:oraStore.centerTypeSelection} , 
         {title : 'Address(eng)' , field:'address'},
         {title: 'City(eng)' , field: 'city'} , 
         {title:'City(heb)' , field: 'city_heb'},
        { title:'Address(heb)' , field: 'address_heb'},
        {title:'Last Change' , tooltip:'last change record' , render: center =>
        //    //<IconButton accessKey = {JSON.stringify({'type':'system','center':system.center ,'name': system.name})} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
           <IconButton accessKey = {center ? JSON.stringify({'type':'center','tag':center.tag}) : ''} onClick={handleInfoClick}> <InfoIcon/> </IconButton>}
      ];  
      options.exportButton = true;
      editable =  {
        onRowAdd: (newData:Center) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              oraStore.addCenter(newData);
            }, 600);
          }),
        onRowUpdate: (newData:Center, oldData:Center) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              if (oldData) {
                  oraStore.updateCenter(oldData,newData);
               }
              
            }, 600);
          }),
        onRowDelete: (oldData:Center) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              oraStore.deleteCenter(oldData);
            }, 600);
          })  
      }
    }
    const handleInfoClick = (event:React.MouseEvent<HTMLButtonElement>) => {
      oraStore.setModal(event.currentTarget.accessKey);
      oraStore.openChangeModal();
    }
  return useObserver(() =>(
    <div>
    <MaterialTable
      title={<Typography variant="h5" > Centers List {oraStore.centerDesc} </Typography>}
      columns={columns}
      data={oraStore.centers}
      options={options}
      editable={editable}
    />
    <LastChangeModal/>
    </div>
  ));
}

