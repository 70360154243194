import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {useObserver} from 'mobx-react-lite';
import {useStore} from '../helpers/use-store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

export default function LastChangeModal() {
    let oraStore = useStore();
  const classes = useStyles();
//   const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   oraStore.openChangeModal();
  // };

  const handleClose = () => {
    oraStore.closeChangeModal();
  };

  return (useObserver(()=>(
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={oraStore.changeModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={oraStore.changeModalOpen}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Last Change</h2>
    <p id="transition-modal-description">{oraStore.modalMessage}</p>
          </div>
        </Fade>
      </Modal>
    </div>
  )));
}